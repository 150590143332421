<template>
  <span ref="template">
    <a-time-picker
      :style="`width:${options.width}`"
      :disabled="options.disabled || parentDisabled"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :format="options.format"
      :value-format="options.valueFormat"
      :value="time"
      :default-value="defaultValue"
      :start-disabled="startDisabled"
      :end-disabled="endDisabled"
      :disabled-hours="getDisabledHours"
      :disabled-minutes="getDisabledMinutes"
      :default-open-value="moment('00:00', 'HH:mm')"
      :get-popup-container="()=>$refs.template"
      @change="handleSelectChange"
    />
    <!-- :get-popup-container="()=>$refs.template" -->
  </span>

</template>
<script>
import moment from 'moment'
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    parentDisabled: {
      type: Boolean,
      default: false
    },
    startDisabled: {
      type: String,
      default: undefined
    },
    endDisabled: {
      type: String,
      default: undefined
    },
    getPopupContainer: {
      type: Function,
      default: null
    }
  },
  computed: {
    time(val) {
      if (!this.value) {
        return undefined
      } else {
        return moment(this.value, this.options.format)
      }
    }
  },
  methods: {
    moment,
    handleSelectChange(time) {
    //   console.log()
    //   let time
    //   if (!val) {
    //     time = ''
    //   } else {
    //     time = val.format(this.options.format)
    //   }
      this.$emit('change', time)
      this.$emit('input', time)
    },
    /*
    startDisabled 限制开始
    endDisabled 限制结束
    */
    getDisabledHours() {
      const hours = []
      const { startDisabled, endDisabled } = this
      if (startDisabled && endDisabled) {
        const startTimeArr = startDisabled?.split(':')
        const endTimeArr = endDisabled?.split(':')
        if (parseInt(endTimeArr[0]) < parseInt(startTimeArr[0])) {
          for (let i = parseInt(endTimeArr[0]) + 1; i < parseInt(startTimeArr[0]); i++) {
            hours.push(i)
          }
        } else {
          for (let i = 0; i < parseInt(startTimeArr[0]); i++) {
            hours.push(i)
          }
          for (let i = 24; i > parseInt(endTimeArr[0]); i--) {
            hours.push(i)
          }
        }
        if (startTimeArr[1] === '59') {
          hours.push(parseInt(startTimeArr[0]))
        }
        if (endTimeArr[1] === '00') {
          hours.push(parseInt(endTimeArr[0]))
        }
      } else if (startDisabled) {
        const timeArr = startDisabled.split(':')
        for (let i = 0; i < parseInt(timeArr[0]); i++) {
          hours.push(i)
        }
        if (timeArr[1] === '59') {
          hours.push(parseInt(timeArr[0]))
        }
      } else if (endDisabled) {
        const timeArr = endDisabled.split(':')
        for (let i = 24; i > parseInt(timeArr[0]); i--) {
          hours.push(i)
        }
        if (timeArr[1] === '00') {
          hours.push(parseInt(timeArr[0]))
        }
      }
      return hours
    },
    getDisabledMinutes() {
      const minutes = []
      const { startDisabled, endDisabled, value } = this
      if (!value) {
        return Array.from(new Array(60).keys())
      }
      const startTimeArr = startDisabled && startDisabled?.split(':') || []
      const endTimeArr = endDisabled && endDisabled?.split(':') || []
      const timeArr = value?.split(':') || []
      if (startTimeArr[0] === timeArr[0] && endTimeArr[0] === timeArr[0]) {
        for (let i = 0; i < parseInt(startTimeArr[1]); i++) {
          minutes.push(i)
        }
        for (let i = 60; i >= parseInt(endTimeArr[1]); i--) {
          minutes.push(i)
        }
      } else if (startTimeArr[0] === timeArr[0]) {
        for (let i = 0; i < parseInt(startTimeArr[1]); i++) {
          minutes.push(i)
        }
      } else if (endTimeArr[0] === timeArr[0]) {
        for (let i = 60; i >= parseInt(endTimeArr[1]); i--) {
          minutes.push(i)
        }
      }
      return minutes || []
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-time-picker-panel-combobox{
  display: flex;
}
</style>
