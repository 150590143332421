<template>
  <div class="statistics">
    <!-- 锁定图标 -->
    <div v-if="isLogo" class="logotype">
      <slot name="logo" />
    </div>
    <!-- 数据标题总览 -->
    <div v-if="isTitle" class="statistics-left" :style="{'minWidth':`${titleWidth?titleWidth:title.length*20+60}px`}">
      <div class="content">
        <div v-if="title" class="title">{{ title }}</div>
        <slot v-else name="title" />
      </div>
    </div>
    <!-- 业务操作区 -->
    <div class="statistics-center">
      <div v-for="(item,index) in dataList" :key="index" class="statistics-center-item">
        <div class="content" :style="{'width': `${(item.label.length+(item.unit?item.unit.length:0)) *20}px`}">
          <div v-if="item.isMoney == true" class="figures" :style="{'textAlign': textAlign}">
            <span v-if="item.num==0 || item.num==null || item.num==undefined">--</span>
            <span v-else>{{ item.num | stateFormatNum }}</span>
          </div>
          <div v-else class="figures" :style="{'textAlign': textAlign}"> {{ item.num===0?'--':item.num }} </div>
          <div class="label" :style="{'textAlign': textAlign}">{{ item.label?item.label:'' }}{{ item.unit?`(${item.unit})`:'' }}</div>
        </div>
      </div>
    </div>
    <!-- 功能操作区 -->
    <div v-if="isFunction" class="statistics-right" :style="{'minWidth':`${minWidth}px`}">
      <div class="content">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
<script>
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  name: 'CbStatistics',
  filters: {
    stateFormatNum
  },
  props: {
    // 左侧标题显隐
    isTitle: {
      type: Boolean,
      default: false
    },
    // 左侧标题
    title: {
      type: String,
      default: ''
    },
    // 右侧按钮显隐
    isFunction: {
      type: Boolean,
      default: false
    },
    // 左上角图标显隐
    isLogo: {
      type: Boolean,
      default: false
    },
    // 中间统计数据
    dataList: {
      type: Array,
      default: () => []
    },
    // 统计数据条数
    length: {
      type: [String, Number],
      default: ''
    },
    // 左侧最小宽度
    titleWidth: {
      type: Number,
      default: null
    },
    // 右侧最小宽度
    minWidth: {
      type: Number,
      default: 320
    },
    // 数据排列方式
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {

    }
  },
  watch: {
  },
  created() {
    if (this.dataList.length < this.length) {
      const length = this.length - this.dataList.length
      for (let i = 0; i < length; i++) {
        this.dataList.push({
          label: '',
          num: '',
          unit: ''
        })
      }
      console.log(this.dataList)
    }
  }

}
</script>
<style lang="less" scoped>
.statistics{
    width: 100%;
    height: 88px;
    border-radius:6px;
    background-color: @sc-greyBg-10;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    .statistics-left{
        // min-width: 280px;
      height: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      .content{
          width: 60%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }
      &:after {
            content: '';
            position: absolute;
            right: 0;
            height: 30px;
            width: 1px;
            background-color: @sc-greyBg-20;
            top: 50%;
            transform: translateY(-50%);
          }
    }
    .statistics-center{
        display: flex;
        width: 100%;
        height: 100%;
        .statistics-center-item{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            .content{
                // width: 70%;
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
    .statistics-right{
        // min-width: 320px;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
         .content{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            height: 30px;
            width: 1px;
            background-color: @sc-greyBg-20;
            top: 50%;
            transform: translateY(-50%);
          }
    }
    .logotype{
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 6px 0 0 6px ;
      min-width: 80px;
      height: 28px;
    }
}
.figures{
  width: 100%;
  color: @sc-grey-100;
  font-size: 20px;
  font-weight: 600;
}
.label{
  width: 100%;
  color: @sc-grey-60;
  font-size: 16px;
}
.title{
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: @sc-grey-100;
}
// 数据间需要分割线的情况下 复制代码到其文件下   nth-child(-n+3)  选择前三个元素
// /deep/.statistics-center-item{
//   position: relative;
//   &:nth-child(-n+3)::after {
//     content: '';
//     position: absolute;
//     right: 0;
//     height: 30px;
//     width: 1px;
//     background-color: @sc-greyBg-20;
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
</style>
