<template>
  <div class="proview-min" :style="`width: ${width}; height: ${height}`">
    <div class="receiver">
      <div class="long-view" />
      <div class="short-view" />
    </div>
    <div class="screen-view">
      <!-- 内容 -->
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PhonePanel',
  props: {
    // 手机模型宽高，要加单位
    width: {
      type: String,
      default: '435px'
    },
    height: {
      type: String,
      default: '700px'
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
	@iponeColor: #E9EEF7;
.proview-min {
  margin:  0 auto;
  border-radius: 50px;
  border: 25px solid @iponeColor;
  padding: 24px 15px 20px;
  position: relative;
  .receiver {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 7px;
    display: flex;
    align-items: center;
    .long-view {
      width: 51px;
      height: 6px;
      border-radius: 5px;
      background: @iponeColor;
			margin-right: 12px;
    }
		.short-view {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background: @iponeColor;
    }
  }
   .screen-view {
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    overflow: auto;
    padding: 20px 0 14px;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
