<template>
  <Modal
    closable
    overflow-auto
    :visible="visible"
    class="preview-tb-p"
    :title="modalTitle + '预览'"
    size="large"
    :footer="null"
    @cancel="handleCancel"
  >
    <!-- tab多个tab时显示 -->
    <!-- <div class="preview-tb-toolbar">
      <a-button @click="download">
        <a-icon type="download" />下载
      </a-button>
    </div> -->
    <a-tabs v-show="!isShow" v-model="activeKey" @change="callback">
      <a-tab-pane v-for="item in tabData" :key="item.tabId + ',' + item.tabName" :tab="item.tabName" />
    </a-tabs>
    <div>
      <div>
        <customTable
          is-report-form
          :columns="columns"
          :data-source="tableList"
          :row-key="'reportId'"
          :pagination="false"
          :loading="loading"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { getReportExport, getPreviewByTabId } from '@/services/organization/reportForm.js'
import { csv } from '@/utils/file-reader.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index.vue'),
    customTable: () => import('@/components/CustomTable/index.vue')
  },
  data() {
    return {
      visible: false,
      modalTitle: '',
      reportId: '',
      columns: [],
      tableList: [],
      loading: false,
      isShow: true,
      activeKey: '',
      tabData: [],
      popData: {},
      params: {}
    }
  },
  methods: {
    // 下载
    download() {
      getReportExport({ tenantReportId: this.reportId, ...this.params, insureMonth: this.popData.insureMonth, insureCompanyIds: this.popData.insureCompanyIds })
    },
    preview(data, params) {
      this.params = params || {}
      this.popData = data
      // 多选
      if (data.multiple === 1) {
        this.previewMultiple(data, params)
        this.isShow = false
      } else {
        this.previewSing(data, params)
        this.isShow = true
      }
      this.visible = true
    },
    previewSing(data) {
      this.modalTitle = data.reportName
      this.reportId = data.reportId
      const csvData = data.csvData
      const columnData = csvData.shift()
      this.columns = columnData.map((v) => {
        return {
          title: v,
          dataIndex: v,
          key: v,
          width: ['姓名', '部门', '岗位', '手机号', '工号', '备注', '班次'].includes(v) ? 140 : v.length * 25,
          ellipsis: true,
          dataType: ['公积金个人缴纳合计', '公积金公司缴纳合计', '公积金合计金额', '医保个人缴纳合计', '医保公司缴纳合计', '医保合计金额', '社保合计金额', '社保公司缴纳合计', '社保个人缴纳合计', '合计金额', '公司缴纳合计', '个人缴纳合计'].includes(v) ? 'money' : '' // 报表指定字段显示千分位
        }
      })
      const dataList = []
      for (let i = 0; i < csvData.length; i++) {
        const dataObj = {}
        const obj = csvData[i]
        for (let j = 0; j < obj.length; j++) {
          dataObj[this.columns[j]['key']] = obj[j]
        }
        dataList.push(dataObj)
      }
      this.tableList = dataList
    },
    previewMultiple(data) {
      this.modalTitle = data.reportName
      this.tabData = data.csvData
      if (this.tabData.length !== 0) {
        this.activeKey = this.tabData[0].tabId + ',' + this.tabData[0].tabName
        this.callback(this.activeKey)
      } else {
        this.columns = []
        this.tableList = []
      }
    },
    handleCancel() {
      this.visible = false
    },
    // 切换table
    callback(val) {
      const pare = { tenantReportId: this.popData.reportId, tabId: val.split(',')[0], tabName: val.split(',')[1], insureMonth: this.popData.insureMonth }
      getPreviewByTabId(pare).then((res) => {
        const blob = new Blob([res], { type: 'text/csv' })
        csv(blob).then((data) => {
          const csvData = data
          if (csvData[csvData.length - 1].every((v) => v === '')) {
            // 去除最后的空行
            csvData.pop()
          }
          this.previewSing({
            ...this.popData,
            csvData: csvData
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.preview-tb-p {
  .preview-tb-toolbar {
    text-align: right;
  }
}
/deep/ .ant-btn {
  margin-bottom: 16px;
  padding: 5px 10px;
}
/deep/ .ant-btn > .anticon {
  color: @sc-primary-100;
}
/deep/ .ant-btn > span {
  color: @sc-grey-100;
  margin-left: 4px;
}
/deep/.modal-content{
  overflow: hidden;
}
</style>
