<template>
  <div>
    <!-- 社保模块弹窗 -->
    <CbModal v-model="visible" size="normal" :title="type==='download'?'下载报表':'预览报表'" :z-index="6" @ok="handleOk" @cancel="cancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="periodId" label="年月" prop="periodId">
          <a-month-picker v-model="form.periodId" placeholder="请选择月份" :disabled-date="disabledStartDate" value-format="YYYY-MM" style="width: 100%" />
        </a-form-model-item>
        <a-form-model-item label="参保主体">
          <a-select v-model="form.contractCo" mode="multiple" placeholder="请选择">
            <a-select-option v-for="item in insureList" :key="item.companyId" :value="item.companyId">{{ item.companyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </CbModal>
  </div>
</template>
<script>
import {
  getInsureCompanyListApi
} from '@/services/insurance/archives'
import moment from 'moment'
export default {
  name: 'InsuranceModel',
  components: {
    CbModal: () => import('@/components/CbModal/index.vue')
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      insureList: [],
      form: {
        periodId: undefined,
        contractCo: []
      },
      rules: {
        periodId: [{ required: true, message: '请选择年月', trigger: 'change' }]
      },
      visible: false,
      type: ''
    }
  },
  mounted() {

  },
  methods: {
    // 禁用未来月份
    disabledStartDate(current) {
      const yearEnd = moment().endOf('month').subtract('month').endOf('month')
      return current > yearEnd
    },
    show(type) {
      this.type = type
      this.visible = true
      this.getInsureCompanyListApi()
    },
    // 获取社保主体
    async getInsureCompanyListApi() {
      const res = await getInsureCompanyListApi()
      this.insureList = res.data
    },
    // 社保
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.type === 'download') {
            this.$emit('download', { insureMonth: this.form.periodId, insureCompanyIds: this.form.contractCo.join(',') })
            this.cancel()
          } else {
            this.$emit('preview', { insureMonth: this.form.periodId, insureCompanyIds: this.form.contractCo.join(',') })
            this.cancel()
          }
        }
      })
    },
    cancel() {
      this.$refs.ruleForm.resetFields()
      Object.assign(this.$data, this.$options.data())
      this.visible = false
      this.form = {
        periodId: undefined,
        contractCo: []
      }
    },
    disabledDate(current) {
      console.log(moment().endOf('day'))
      return current && current > moment().startOf('day')
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/ .ant-modal-body {
    padding: 20px 24px 14px;
  }
  /deep/ .ant-form-item-label > label {
    color: @sc-grey-80;
  }
  /deep/ .ant-btn-primary {
    margin-right: -5px;
  }
</style>
