<template>
  <div class="upload-image">
    <a-upload
      name="avatar"
      :list-type="listType"
      :disabled="disabled"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :multiple="true"
      :custom-request="customRequest"
      @change="handleChange"
    >
      <a-button><a-icon type="upload" /> {{ btnText }}</a-button>
    </a-upload>
    <div class="uploadDescription">{{ uploadDescription }}</div>
    <div v-if="showUploadList" class="fileList">
      <template v-for="(item, flieIndex) in fileList">
        <div :key="`fileItem${flieIndex}`" class="fileItem">
          <div class="fileIcon">
            <img :src="item.thumbUrl" alt="" srcset="">
          </div>
          <div class="fileName">{{ item.name }}</div>
          <div class="icon">
            <svg v-if="isDelete" class="iconpark-icon Circle-Button" @click="onDelete(item,flieIndex)"><use href="#close-small" /></svg>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { upload, getSignature } from '@/services/upload/index.js'
import { IMG_PREVIEW } from '@/services/api.js'

/**
 * typeList<Array>
 */
export default {
  name: 'UploadImage',
  model: {
    prop: 'propFileList',
    event: 'change'
  },
  props: {
    btnText: {
      type: String,
      default: '上传附件'
    },
    uploadDescription: {
      type: String,
      default: ''
    },
    // 文件类型列表
    typeList: {
      default: () => ['*'],
      type: Array
    },
    // 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
    listType: {
      type: String,
      default: 'text'
    },
    // 大小 单位M
    fileSize: {
      default: 2,
      type: Number
    },
    // 最大上传数量
    maxSum: {
      default: 2,
      type: Number
    },
    // 文件列表
    propFileList: {
      type: Array,
      default: () => []
    },
    // 是否展示文件列表
    showUploadList: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    // 下面为业务属性
    // 文件类型,头像为avatar,其他文件为other
    fileType: {
      default: 'other',
      type: String,
      require: true
    },
    // 0 不敏感 1敏感
    sensitiveFile: {
      default: '1',
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      fileList: [],
      loading: false,
      index: -1,
      previewVisible: false, // 预览弹窗显示
      previewImage: '' // 预览图片路径
    }
  },
  watch: {
    propFileList(newVal) {
      // const targetVal = newVal.map((item, index) => {
      //   return {
      //     ...item,
      //     uid: index, // 注意，这个uid一定不能少，否则上传失败
      //     // name: item.fileName,
      //     status: 'done',
      //     url: item.fileUrl,
      //     percent: 100
      //   }
      // })
      // // 防止死循环
      // if (newVal.length === 0 || newVal[newVal.length - 1].status) return
      // this.$set(this, 'fileList', targetVal)
    }
  },
  methods: {
    handleChange({ file, fileList }) {
      // 删除文件
      if (file.status && file.status === 'removed') {
        this.fileList = fileList
        this.index = fileList.length - 1
        this.$emit('fileDel', { file, fileList })
      }
    },
    // 上传前验证
    beforeUpload({ name, size }) {
      return new Promise((resolve, reject) => {
        // 判断类型
        const { typeList, fileSize } = this
        if (!typeList.includes('*')) {
          const singleType = name.toLowerCase().split('.')
          const typeInclude = typeList.includes(singleType[1])
          if (!typeInclude) {
            this.$message.error('上传文件格式错误, 请重新选择')
            return reject(false)
          }
        }
        // 判断大小
        const isLt2M = size / 1024 / 1024 < fileSize
        if (!isLt2M) {
          this.$message.error(`文件最大上传${fileSize}M`)
          return reject(false)
        }
        resolve(true)
      })
    },
    // 自定义上传的方法
    async customRequest(file) {
      // 设置文件的 realIndex 值
      // 该值用来计算 文件上传的相关信息
      const realIndex = ++this.index
      // 判断是否超出数量
      if (realIndex > this.maxSum - 1) {
        this.$message.error(`最多上传${this.maxSum}个`)
        this.index--
        return
      }
      console.log('aaaaaaaaaaaa', file.file)
      const imgItem = {
        uid: realIndex, // 注意，这个uid一定不能少，否则上传失败
        name: file.file.name,
        status: 'uploading',
        url: '',
        thumbUrl: this.findIcon(file.file.name),
        percent: 0 // 注意不要写100。100表示上传完成
      }
      // this.fileList.push(imgItem)
      const form = new FormData()
      // const realindex = this.fileList.length - 1
      form.append('file', file.file)
      form.append('fileName', file.file.name)
      form.append('fileType', this.fileType)
      form.append('sensitiveFile', this.sensitiveFile)
      // 请求上传文件
      const res = await upload(form)
      if (res.code === 100) {
        let url = ''
        if (this.sensitiveFile === '1') {
          const signatureRes = await getSignature({
            id: res.data.fileId
          })
          if (signatureRes.code === 100) {
            url = `${IMG_PREVIEW}/img?id=${res.data.fileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
          }
        } else {
          url = `${IMG_PREVIEW}/img?id=${res.data.fileId}`
        }
        // rimgItem.name = res.data.fileName
        // rimgItem.originFileName = res.data.originFileName
        imgItem.fileId = res.data.fileId
        imgItem.staffFileId = res.data.staffFileId
        imgItem.url = url
        imgItem.status = 'done'
        // this.$set(this.fileList, (realindex), rimgItem)
        this.fileList.push(imgItem)
        this.$emit('fileUpload', { file: form, fileList: this.fileList, fileId: res.data.fileId })
      }
    },
    findIcon(fileName) {
      const name = fileName.toLowerCase().split('.')
      if (['doc', 'docx'].includes(name[1])) {
        return require('@/assets/img/upload/word_file.png')
      } else if (['pdf'].includes(name[1])) {
        return require('@/assets/img/upload/pdf_file.png')
      } else if (['xls', 'xlsx'].includes(name[1])) {
        return require('@/assets/img/upload/excel_file.png')
      } else if (['ppt', 'pptx'].includes(name[1])) {
        return require('@/assets/img/upload/ppt_file.png')
      } else if (['txt'].includes(name[1])) {
        return require('@/assets/img/upload/txt_file.png')
      }
      return ''
    },
    // 单独预览
    previewImg(url) {
      this.previewImage = url
      this.previewVisible = true
    },
    // 单独下载
    handleDownload(file) {
      window.location.href = `${this.basePath}/hrsaas-storage/file?id=${file.fileId}&signature=${file.signature}&expireTime=${file.time}`
    },
    onDelete(file, flieIndex) {
      this.$Reconfirm({
        title: '提示',
        content: `确认删除《${file.name}》这个文件吗？`
      }).then(() => {
        this.fileList.splice(flieIndex, 1)
        this.index--
        this.$message.success('删除成功')
        this.$emit('onDelete', flieIndex)// 返回文件列表删除文件的下标
      }).catch(() => {
        console.log('cancel')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.uploadDescription{
  font-size: 12px;
  line-height: 20px;
  color: @sc-grey-20;
}
.fileList{
  display: flex;
}
.Circle-Button {
  width: 16px;
  height: 16px;
  margin: auto 0 auto 8px;
  color: @sc-grey-60;
  vertical-align: top;
}
.icon{
  width: 16px;
  height: 16px;
  cursor: pointer;
}
</style>
